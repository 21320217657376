import React from "react";
import { menuActions, authActions, dialogActions, jobActions, guideActions } from "../../actions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Tile from "../../components/Tile";
import Navbar from "../../components/Navbar";
import Dialog from "../Dialog";
import getFormattedPrice from "../../common/utils/getFormattedPrice";
import styles from "./Dashboard.module.scss";
import getCountryInfoByLanguage from "../../common/utils/getCountryInfoByLanguage";
import { getCurrentLanguage } from "../../actions/auth";
import { withTranslation } from "react-i18next";

class Dashboard extends React.Component {
  state = {
    reset: false
  };

  keypress = event => {
    console.log(document.activeElement.tagName);
    if (document.activeElement.tagName !== "INPUT")
      if (event.code === "KeyP") {
        this.props.jobActions.getJobs();
        this.props.authActions.resetQuota();
        this.props.authActions.resetCredit();
        this.setState({ reset: true });
      }
  };

  componentDidMount() {
    if (this.props.jobs.jobs.length === 0) {
      this.props.jobActions.getJobs();
    }
    if (this.props.menu.length === 0) {
      this.props.menuActions.getMenu(this.props.auth.topmenutype);
    }
    this.props.guideActions.next();

    document.addEventListener("keypress", this.keypress);
  }

  componentWillUnmount() {
    document.removeEventListener("keypress", this.keypress);
  }

  handleClick = tile => {
    switch (tile.action) {
      case 1:
      case 206:
        this.props.menuActions.displaySubMenu(tile.children);
        break;
      case 2:
        if (!tile.count) {
          tile.count = 3; //TODO: Find a better solution, tile.count returns undefined for users 22 and 33
        }
        const user = this.props.user;
        const notPrintedJobs = this.props.jobs.jobs.filter(job => !job.printed);
        const formattedSum = notPrintedJobs.map(x => x.price).reduce((a, b) => a + b, 0);

        if (this.state.reset) {
          tile.count = this.props.jobs.jobs.filter(job => !job.printed).length;
        }

        if (
          user.username === "credit" &&
          (user.credit <= 0.1 || formattedSum > user.credit) &&
          notPrintedJobs > 0
        ) {
          this.props.dialogActions.openDialog("A69", "Not enough credit");
        } else if (tile.count > 0) {
          user.username === "quota" && this.props.authActions.changeQuota(tile.count);
          user.username === "credit" && this.props.authActions.changeCredit(formattedSum);
          this.props.menuActions.printAll(tile.id);
          this.print(tile.count);
        }
        break;
      case 3:
        this.props.history.push(`/jobs`);
        break;
      case 7:
        this.scan();
        break;
      case 8:
        this.scan();
        break;
      case 9:
        this.scan();
        break;
      case 91:
        this.scan(2);
        break;
      case 10:
        this.props.history.push(`/application-list`);
        break;
      case 106:
        this.print();
        break;
      case 205:
        this.props.history.push("/register-id");
        break;
      default:
        this.props.dialogActions.openDialog("A" + tile.action, tile.name);
    }
  };

  scan = (count = 1) => {
    this.props.history.push(`/animation/scan/${count}`);
  };
  scanNavigate = (count = 1) => {
    this.props.history.push(`/animation/scan-navigate/${count}`);
  };
  print = (count = 1) => {
    this.props.history.push(`/animation/print/${count}`);
  };
  folderBrowse = (count = 1) => {
    this.props.history.push(`/animation/folder-browse/${count}`);
  };
  pdfPass = (count = 1) => {
    this.props.history.push(`/animation/pdf-pass/${count}`);
  };
  classification = (count = 1) => {
    this.props.history.push(`/animation/classification/${count}`);
  };
  smartWorkflows = (count = 1, type = "Contract") => {
    this.props.history.push(
      `/animation/smart-workflows/${count}/${type.toLowerCase().replace(" ", "-")}`
    );
  };

  render() {
    const { menu, level = menu.length - 1, authActions, menuActions, user, t } = this.props;
    const notPrintedJobs = this.props.jobs.jobs.filter(job => !job.printed);

    const jobCount = notPrintedJobs.length;

    const formattedSum = notPrintedJobs.map(x => x.price).reduce((a, b) => a + b, 0);
    return (
      <div
        className={styles["k42-dashboard"]}
        style={
          process.env.REACT_APP_VENDOR === "myq" && user.background === "white"
            ? { backgroundColor: "white" }
            : {}
        }>
        <Navbar
          level={level}
          navigateBack={menuActions.navigateBack}
          logout={authActions.logoutUser}
          currentLanguage={authActions.getCurrentLanguage}
          user={user}
          t={t}
        />
        {menu[level] &&
          menu[level].map((item, index) => {
            if (item.showJobs) {
              return (
                <Tile
                  key={index}
                  data={item}
                  user={user}
                  click={this.handleClick}
                  jobs={{
                    count: jobCount,
                    sum: getFormattedPrice(
                      (
                        getCountryInfoByLanguage(getCurrentLanguage()) || {
                          countryCode: "US",
                          currency: "USD"
                        }
                      ).currency,
                      formattedSum,
                      (
                        getCountryInfoByLanguage(getCurrentLanguage()) || {
                          countryCode: "US",
                          currency: "USD"
                        }
                      ).countryCode
                    )
                  }}
                />
              );
            }
            return <Tile key={index} user={user} data={item} click={this.handleClick} />;
          })}
        <Dialog
          scan={this.scan}
          scanNavigate={this.scanNavigate}
          print={this.print}
          folderBrowse={this.folderBrowse}
          pdfPass={this.pdfPass}
          classification={this.classification}
          smartWorkflows={this.smartWorkflows}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  ...state,
  jobs: state.jobs,
  user: state.auth
});

const mapDispatchToProps = dispatch => ({
  menuActions: bindActionCreators(menuActions, dispatch),
  dialogActions: bindActionCreators(dialogActions, dispatch),
  authActions: bindActionCreators(authActions, dispatch),
  jobActions: bindActionCreators(jobActions, dispatch),
  guideActions: bindActionCreators(guideActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Dashboard));