import React from "react";
import { dialogActions, jobActions, authActions } from "../../actions/index";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Dialog as DialogComponent } from "../../components/Dialog";

class Dialog extends React.Component {
  setActiveTab = index => {
    this.props.dialogActions.setActiveTab(index);
  };

  handleAction = (id, value, name) => {
    switch (id) {
      case 104:
        this.props.dialogActions.closeDialog();
        break;
      case 105:
        this.props.dialogActions.saveValue(name, value);
        this.props.dialogActions.closeDialog();
        break;
      case 106:
        this.props.dialogActions.closeDialog();
        this.props.scan();
        break;
      case 107:
        this.props.dialogActions.closeDialog();
        this.props.print();
        break;
      case 108:
        const selectedJobsCount = this.props.jobs.filter(job => job.selected).length;
        const user = this.props.user;
        const selectedJobsPricesSum = this.props.jobs
          .filter(job => job.selected)
          .map(x => x.price)
          .reduce((a, b) => a + b, 0);
        if (
          user.username === "credit" &&
          (user.credit <= 0.1 || selectedJobsPricesSum > user.credit)
        ) {
          this.props.dialogActions.openDialog("A69", "Not enough credit");
          break;
        } else {
          this.props.dialogActions.closeDialog();
          user.username === "quota" && this.props.authActions.changeQuota(selectedJobsCount);
          user.username === "credit" && this.props.authActions.changeCredit(selectedJobsPricesSum);
          this.props.jobActions.printJobsRequest();
          this.props.print(selectedJobsCount);
          return setTimeout(() => {
            this.props.jobActions.printJobsSuccess(selectedJobsCount);
          }, 400); // Animation
        }
      case 109:
        this.props.dialogActions.closeDialog();
        this.props.dialogActions.closeDialog();
        this.props.dialogActions.closeDialog();
        this.props.folderBrowse();
        break;
      case 110:
        this.props.dialogActions.closeDialog();
        this.props.dialogActions.closeDialog();
        this.props.dialogActions.closeDialog();
        this.props.dialogActions.closeDialog();
        this.props.dialogActions.closeDialog();
        this.props.pdfPass();
        break;
      case 111:
        this.props.smartWorkflows(1, name.replace(": Please select the classification level:", ""));

        this.props.dialogActions.openDialog("A111", value);
        this.props.dialogActions.saveSecret(value);
        this.props.dialogActions.closeDialog();
        setTimeout(() => {
          this.props.dialogActions.closeDialog();
          this.props.dialogActions.closeDialog();
          this.props.dialogActions.closeDialog();
          this.props.dialogActions.closeDialog();
          this.props.dialogActions.closeDialog();
          this.props.dialogActions.closeDialog();

          this.props.classification();
        }, 4000);
        break;
      case 502:
        this.props.dialogActions.openDialog(`A502`, `Please select the document type:`);
        this.props.dialogActions.saveValue(name, value);
        break;
      case 503:
        this.props.dialogActions.openDialog(
          `A503`,
          `${value}: Please select the classification level:`
        );
        break;
      default:
        if (id) this.props.dialogActions.openDialog(`A${id}`, value);
    }
  };

  render() {
    return this.props.dialogs.map((dialog, index) => (
      <DialogComponent
        key={index}
        setActiveTab={this.setActiveTab}
        handleAction={this.handleAction}
        opened={dialog.opened}
        label={dialog.label}
        activeTab={dialog.activeTab}
        buttons={dialog.buttons}
        type={dialog.type}
        content={dialog.content}
        dialogActions={this.props.dialogActions}
      />
    ));
  }
}

const mapStateToProps = state => ({
  ...state.jobs,
  dialogs: state.dialog,
  credit: state.auth.credit,
  user: state.auth
});

const mapDispatchToProps = dispatch => ({
  dialogActions: bindActionCreators(dialogActions, dispatch),
  jobActions: bindActionCreators(jobActions, dispatch),
  authActions: bindActionCreators(authActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Dialog);
