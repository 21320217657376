import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import styles from "./Animation.module.scss";
import Icon from "../Icon";
import { useSelector } from "react-redux";
import OneStepGuide from "../../containers/Guide/OneStepGuide";

const Animation = ({ match, history, t }) => {
  const pathRegEx = /\/animation/g;
  let pageLayout = "";
  const dialog = useSelector(state => state.dialog);
  const [secret, setSecret] = useState("Classified");
  const [secretType, setSecretType] = useState("");

  useEffect(() => {
    if (dialog[4]) {
      setSecret(dialog[4].label);
    }
    setSecretType(match.params.secretType);
  }, [dialog]);

  const closeAnimation = (defaultTime = 2000) => {
    setTimeout(() => {
      if (pathRegEx.test(history.location.pathname)) history.goBack();
    }, defaultTime * match.params.count);
  };

  switch (match.params.type) {
    case "print":
      closeAnimation();
      pageLayout = (
        <div className={styles["k42-animation"]}>
          <div className={styles["k42-animation-container"]}>
            <img
              className={styles["k42-page-print"]}
              alt={t(`Printed Page`)}
              src={`${process.env.PUBLIC_URL}/img/paper.png`}
            />
          </div>
        </div>
      );
      break;
    case "scan":
      closeAnimation();
      pageLayout = (
        <div className={styles["k42-animation"]}>
          <div className={styles["k42-animation-container"]}>
            <div className={styles["k42-page-scan"]} />
            <img
              className={styles["k42-page"]}
              alt={t(`Printed Page`)}
              src={`${process.env.PUBLIC_URL}/img/paper.png`}
            />
          </div>
        </div>
      );
      break;
    case "copy":
      closeAnimation();
      pageLayout = (
        <div className={styles["k42-animation"]}>
          <div className={styles["k42-animation-container"]}>
            <div className={styles["k42-page-scan"]} />
            <img
              className={styles["k42-page-print"]}
              alt={t(`Printed Page`)}
              src={`${process.env.PUBLIC_URL}/img/paper.png`}
            />
          </div>
        </div>
      );
      break;
    case "folder-browse":
      pageLayout = (
        <>
          <button className={styles["k42-close-button"]} onClick={() => closeAnimation(0)}>
            <Icon name={"close"} />
          </button>
          <div className={styles["k42-animation-container"]}>
            <img
              className={styles["k42-page-pdf-pass"]}
              alt={t(`Folder browse`)}
              src={`${process.env.PUBLIC_URL}/img/folder-browse.png`}
            />
          </div>
        </>
      );
      break;
    case "pdf-pass":
      pageLayout = (
        <>
          <button className={styles["k42-close-button"]} onClick={() => closeAnimation(0)}>
            <Icon name={"close"} />
          </button>
          <div className={styles["k42-animation-container"]}>
            <img
              className={styles["k42-page-pdf-pass"]}
              alt={t(`PDF Password`)}
              src={`${process.env.PUBLIC_URL}/img/pdf-pass.png`}
            />
          </div>
        </>
      );
      break;
    case "scan-navigate":
      pageLayout = (
        <div className={styles["k42-animation"]}>
          <div className={styles["k42-animation-container"]}>
            <div className={styles["k42-page-scan"]} />
            <img
              className={styles["k42-page"]}
              alt={t(`Printed Page`)}
              src={`${process.env.PUBLIC_URL}/img/paper.png`}
            />
          </div>
        </div>
      );
      break;
    case "smart-workflows":
      pageLayout = (
        <div className={styles["k42-animation"]}>
          <div className={styles["k42-animation-container"]}>
            <div className={styles["k42-classification-scan"]} />
            <div style={{ width: "50%", height: "75%", display: "flex", justifyContent: "center" }}>
              <img
                className={styles["k42-page"]}
                alt={t(`Printed Page`)}
                src={`${process.env.PUBLIC_URL}/img/${secretType}.png`}
              />
            </div>
            <span style={{ marginTop: 20 }}>For illustration purposes only</span>
          </div>
        </div>
      );
      break;
    case "classification":
      pageLayout = (
        <>
          <button
            className={styles["k42-close-button"]}
            onClick={() => history.push("/dashboard")}
            style={{ zIndex: 101 }}>
            <Icon name={"close"} />
          </button>
          <div className={styles["k42-animation-container"]}>
            <div className={styles["k42-image-container"]}>
              <div style={{ position: "relative" }} id="classification-container">
                <img
                  alt={t(`PDF Password`)}
                  src={`${process.env.PUBLIC_URL}/img/classification.png`}
                />
                <span
                  style={{
                    position: "absolute",
                    zIndex: 2,
                    fontFamily: "Segoe",
                    color: "black"
                  }}>
                  {localStorage.getItem("fileName")} - {secret}.pdf
                </span>
              </div>
            </div>
          </div>
          <OneStepGuide id="guide" stepName="classification" />
        </>
      );
      break;
    default:
      pageLayout = "animation";
  }

  return <div className={styles["k42-animation"]}>{pageLayout}</div>;
};

export default withTranslation()(Animation);
