export default width => {
  switch (width) {
    case 600:
      return process.env.PUBLIC_URL + "/img/poweredbymyq_logo_600.png";
    case 1024:
      return process.env.PUBLIC_URL + "/img/poweredbymyq_logo_1024.png";
    case 1920:
      return process.env.PUBLIC_URL + "/img/poweredbymyq_logo_1920.png";
    default:
      return process.env.PUBLIC_URL + "/img/poweredbymyq_logo_1024.png";
  }
};
