import {
  CLOSE_DIALOG_SUCCESS,
  OPEN_DIALOG_SUCCESS,
  SAVE_DIALOG_VALUE,
  SAVE_SECRET,
  SET_DIALOG_ACTIVE_TAB
} from "../constants/ActionTypes";

const initialState = [];

export default function dialog(state = initialState, action) {
  switch (action.type) {
    case OPEN_DIALOG_SUCCESS:
      return [
        ...state,
        {
          ...action.dialog,
          label: action.label,
          message: action.message,
          opened: true
        }
      ];

    case CLOSE_DIALOG_SUCCESS:
      return [...state.slice(0, state.length - 1)];

    case SET_DIALOG_ACTIVE_TAB:
      return state.map((dialog, index) => {
        if (index === state.length - 1) {
          return { ...dialog, activeTab: action.activeTab };
        }
        return dialog;
      });

    case SAVE_DIALOG_VALUE:
      return state.map(dialog => findAndReplace(dialog, action.name, action.value));

    case SAVE_SECRET:
      return [...state, { secret: action.name }];
    default:
      return state;
  }
}

const findAndReplace = (dialog, name, value) => {
  console.log(dialog);
  return {
    ...dialog,
    content: dialog.content.map(dialog => {
      if (dialog.name === name) {
        return { ...dialog, value: value };
      }
      if (dialog.content) {
        return findAndReplace(dialog, name, value);
      }
      return dialog;
    })
  };
};
