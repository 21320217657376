import React from "react";
import { Page, Input } from "react-onsenui";
import styles from "./Dialog.module.scss";
import Icon from "../Icon";

const DialogPrompt = ({ label, fields, handleAction, password }) => {
  return (
    <Page className={styles["k42-dialog-prompt"]}>
      <section className={styles["k42-dialog-prompt-box"]}>
        {label[0].type === "numberinput" && <label className={styles["k42-dialog-prompt-label"]}>{label[0].name}</label>}
        <Input
          float
          className={styles["k42-dialog-prompt-input"]}
          value=""
          type={password ? "password" : "text"}
          onChange={event => {
            handleAction(event.target.value);
          }}
          modifier="material"
          placeholder=""
        />
        {label[0].type === "numberinput" &&<Icon name={"prompt"} size="small" />}
      </section>
    </Page>
  );
};

export default DialogPrompt;
